import React from 'react';
import { Link, useParams } from 'react-router-dom';

import pages from './pages.json';

const PageList = () => {
    const params = useParams();

    return pages[params.role] ? (
        <ul>
            { Object.keys(pages[params.role]).map(key => (
                <li>
                    <Link to={`/${params.role}/${key}`}>
                        {pages[params.role][key].title}
                    </Link>
                </li>
            ))}
        </ul>
    ) : 'Нет страниц';
};

export default PageList;
