import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import { Layout } from 'antd';

import 'antd/dist/antd.css';
import '../styles/index.scss';
import Page from './Page';
import PageList from './PageList';

const { Header, Content, Footer } = Layout;
export const history = createBrowserHistory();

const MainPage = () => (
    <div>
        <Link to="/promoter/instruction">Инструкция для промоутера</Link><br/>
        <Link to="/supervisor/instruction">Инструкция для супервайзера</Link><br/>
        <Link to="/admin/instruction">Инструкция для администратора</Link>
    </div>
);

class App extends Component {
    state = {
        error: false
    };

    componentDidCatch(error) {
        this.setState({ error: true });
    }

    render() {
        return (
            <BrowserRouter history={history}>
                <Layout>
                    <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                        {/* <Menu theme="dark" mode="horizontal">
                            <Menu.Item>
                                <Link to="/promouter/instruction">Инструкция для промоутера</Link>
                            </Menu.Item>
                        </Menu> */}
                        <svg width="127" height="24" viewBox="0 0 127 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M118.272 0.423529L114.33 6V0.423529H107.219V23.5059H114.33V8.68235H120.806L126.72 0.423529H118.272ZM91.0976 17.3647C88.0704 17.3647 85.888 15.1059 85.888 12C85.888 8.89412 88.1408 6.56471 91.0976 6.56471C94.0544 6.56471 96.3072 8.89412 96.3072 12C96.3776 15.1059 94.1248 17.3647 91.0976 17.3647ZM91.0976 0C84.1984 0 78.7776 5.29412 78.7776 12C78.7776 18.7059 84.1984 24 91.0976 24C97.9968 24 103.418 18.7059 103.418 12C103.488 5.29412 98.0672 0 91.0976 0ZM68.7808 17.3647C65.5424 17.3647 63.2896 15.1765 63.2896 12C63.2896 8.89412 65.6128 6.56471 68.64 6.56471C70.1888 6.56471 71.7376 7.2 72.864 8.32941L76.736 2.89412C74.5536 1.05882 71.6672 0 68.64 0C61.6704 0 56.1792 5.29412 56.1792 12C56.1792 18.8471 61.5296 24 68.64 24C72.0192 24 74.8352 23.0118 77.1584 20.9647L73.2864 15.4588C72.2304 16.4471 70.6816 17.3647 68.7808 17.3647ZM43.296 0C41.2544 0 37.8752 0.988235 36.6784 2.47059V0.423529H29.7792V23.5059H36.8896V8.89412C37.664 7.90588 39.4944 6.56471 41.6768 6.56471C42.8736 6.56471 43.9296 6.91765 44.5632 7.62353C45.1264 8.18824 45.6896 9.03529 45.6896 11.2941V23.5059H52.8V8.68235C52.8 2.89412 48.576 0 43.296 0ZM12.32 17.3647C9.2928 17.3647 7.1104 15.1059 7.1104 12C7.1104 8.89412 9.3632 6.56471 12.32 6.56471C15.2768 6.56471 17.5296 8.89412 17.5296 12C17.5296 15.1059 15.3472 17.3647 12.32 17.3647ZM17.248 2.47059C15.7696 0.705882 13.5872 0 11.4048 0C4.9984 0 0 5.29412 0 12C0 15.0353 1.056 17.8588 2.9568 20.0471C5.0688 22.5176 8.3776 23.9294 11.7568 23.9294C13.7984 23.9294 16.1216 23.0824 17.3184 21.5294V23.5059H24.2176V0.423529H17.3184V2.47059H17.248Z" fill="white"/>
                        </svg>
                    </Header>
                    <Content className="site-layout">
                        { this.state.error ? (
                            <div style={{ textAlign: 'center' }}>Ошибка приложения</div>
                        ) : (
                            <Switch>
                                <Route path='/:role/:page' component={Page} />
                                <Route path='/:role' component={PageList} />
                                <Route path='/' component={MainPage} />
                            </Switch>
                        )}
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>©2021 Ancor</Footer>
                </Layout>
            </BrowserRouter>
        );
    }
}

export default App;
