import React from 'react';
import { useParams } from 'react-router-dom';
import Zoom from 'react-medium-image-zoom';

import 'react-medium-image-zoom/dist/styles.css';

import pages from './pages.json';

const isMobile = () => document.body.clientWidth < 768;

const Page = () => {
    const params = useParams();
    const page = pages[params.role] && pages[params.role][params.page];

    return page ? (
        <div>
            <h1 style={{textAlign: 'center'}}>{page.title}</h1>
            { page.steps.map((step, i) => (
                <div key={'section' + (i + 1)} id={'section' + (i + 1)} className='item pagebreak'>
                    <a href={'#section' + (i + 1)}><h2><span className="num">{i+1}</span><span>{step.title}</span></h2></a>
                    <div className="content">
                        <p dangerouslySetInnerHTML={{ __html: step.text }} />
                        { isMobile() ? (
                            <img className='image' src={`/images/${step.img}`} alt={step.title} />
                        ) : (
                            <Zoom>
                                <img className='image' src={`/images/${step.img}`} alt={step.title} />
                            </Zoom>
                        )}
                    </div>
                </div>
            ))}
        </div>
    ) : 'Нет страницы';
};

export default Page;
